<template>
  <div>
    <v-list v-if="this.reference.length > 0" subheader two-line>
      <v-subheader inset
        >Documentation du modéle {{ this.reference }}</v-subheader
      >

      <v-list-item v-for="file in files" :key="file.fileId">
        <v-list-item-avatar>
          <v-img :src = "getImageSrc(file)"/>
<!--          <v-icon :class="file.color" dark v-text="file.icon"></v-icon>-->
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title v-text="file.title"></v-list-item-title>

          <v-list-item-subtitle v-text="file.format"></v-list-item-subtitle>
        </v-list-item-content>

        <v-list-item-action>
          <v-btn @click="download(file)">
            Télécharger
            <!--          <v-icon color="grey lighten-1">mdi-download</v-icon>-->
          </v-btn>
        </v-list-item-action>
      </v-list-item>
    </v-list>
  </div>
</template>

<script>
export default {
  name: "FileList",

  metaInfo() {
    return {
      changed: meta => (this.title = meta.titleChunk.toUpperCase())
    };
  },

  watch: {
    // whenever reference changes, this function will run
    reference: async function(newValue) {
      this.files = [];
      this.files = await this.getfiles(newValue);
    }
  },

  async mounted() {
    this.files = [];
    this.files = await this.getfiles(this.reference);
  },
  methods: {

    getImageSrc(file) {
      return require(`../assets/flag/${file.lang}.png`);
    },

    async getfiles(ref) {
      let tempFile = await this.$store.dispatch("getFiles", ref);
      tempFile = tempFile.map(x => {
        return {
          fileId: x.fileId,
          color: "blue",
          lang: x.lang,
          format: "pdf",
          title: x.lang === "fr" ? "Notice d'utilisation" : "User manual",
          url: "https://www.texasdefrance.com/pdf/" + ref + "?lang=" + x.lang
        };
      });
      return tempFile;
    },

    download(file) {
      window.open(file.url, '_blank');
    }
  },
  provide: {
    heading: { align: "center" }
  },
  data: () => ({
    files: []
  }),
  props: {
    reference: String
  }
};
</script>
